import React from "react";
import { graphql } from "gatsby";
import Button from "../components/Button";
import Layout from "../components/Layout";
import Header from "../components/Header";
import WhyJoin from "../components/WhyJoin";
import TileButton from "../components/TileButton";
import TopicLinks from "../components/TopicLinks";

import { Flex } from "../style/Grid";
import { Heading } from "../style/Heading";
import { Text } from "../style/Text";
import { ImageTile } from "../style/ImageTile";
import { Image } from "../style/Image";
import TriangleSharp from "../style/Header/triangleSharp";
import { URL } from "../constants";
import usePageView from "../utils/usePageView";
import SEO from "../components/seo";

import eyShape from "../images/eyShape.svg";
import nbsp from "../utils/nbsp";

const HomePage = ({ data, location }) => {
  usePageView(location.pathname, "Homepage", "");

  const { frontmatter: content } = data.mdx;

  return (
    <Layout location={location}>
      <SEO title={content.metaTitle} description={content.metaDescription} />
      <Header
        bgImage={content.headerImage.image}
        height={["500px", null, "814px", null, null]}
        bottomComponents={<TriangleSharp />}
        shadowAfter={true}
        css={{
          ":after": {
            backgroundImage:
              "linear-gradient(4.2deg, rgba(46, 46, 56, 0.8) 0%, rgba(91, 91, 105, 0) 100%)",
          },
        }}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          width={["auto", null, null, "630px", null]}
          height={["auto", null, null, "467px", null]}
          backgroundRepeat="no-repeat"
          backgroundSize="100% auto"
          position="relative"
        >
          <Heading
            as="h1"
            color="white.100"
            fontSize={[4, null, 5, "44px", null]}
            mt={[0, null, null, "150px", null]}
            mx={[0, null, null, "55px", null]}
            mb={["25px", null, null, 0, null]}
            fontWeight="light"
            lineHeight={[3, null, null, "64px", null]}
            maxWidth={["200px", null, "550px", null, null]}
            backgroundPosition="center center"
          >
            {nbsp(content.title)}
            <Text
              as="p"
              fontSize={2}
              mt={[0, null, null, "20px", null]}
              lineHeight={[3, null, null, "34px", null]}
            >
              {nbsp(content.subTitle)}
            </Text>
          </Heading>
          <Button
            href={content?.ctaLink || URL.jobs}
            mt={[0, null, null, "auto", null]}
            mx={[0, null, null, "55px", null]}
          >
            {content?.ctaText}
          </Button>
          {data?.tenderImage2?.childImageSharp?.fluid && (
            <Image
              src={eyShape}
              coverSVG
              display={["none", null, null, `block`, null]}
            />
          )}
        </Flex>
      </Header>
      <WhyJoin content={content.whybewithey} />
      <section>
        <Flex
          alignItems="center"
          justifyContent="center"
          bg="EYOffBlack.default"
          px="15px"
        >
          <Heading
            as="h2"
            color="white.100"
            fontSize={["4", null, "6", null, null]}
            mb="50px"
            mt={["0px", null, "50px", null, null]}
            fontWeight="light"
            textAlign="center"
          >
            Koho právě hledáme?
          </Heading>
        </Flex>
        <Flex>
          <TileButton
            as="a"
            href={"https://ey.jobs.cz/zkuseni-profesionalove/"}
            width="50%"
            tileColor="dark"
            tileOrientation="left"
          >
            Zkušené
            <br />
            odborníky
          </TileButton>
          <TileButton
            to={URL.jobs + "?experienceRequired=student,false"}
            width="50%"
            tileColor="light"
            tileOrientation="right"
            textAlign="right"
          >
            Studenty i&nbsp;čerstvé
            <br />
            absolventy
          </TileButton>
        </Flex>
      </section>

      <section>
        <Flex
          alignItems="center"
          justifyContent="center"
          bg="white.100"
          px="15px"
        >
          <Heading
            as="h2"
            color="EYOffBlack.default"
            fontSize={["4", null, "6", null, null]}
            mt={["60px", null, "120px", null, null]}
            mb={["40px", null, "60px", null, null]}
            fontWeight="light"
            textAlign="center"
          >
            Jak probíhá výběrové řízení?
          </Heading>
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="center"
          bg="white.100"
          flexDirection={["column", null, "row", null, null]}
        >
          <ImageTile
            to={`${URL.blog}/60-4-rady-jak-zvladnout-pohovor-do-ey/`}
            mr={["0px", null, "16px", null, null]}
            mb={["16px", null, "0px", null, null]}
          >
            <Heading
              as="h3"
              color="white.100"
              fontSize={["3", null, "5", null, null]}
              fontWeight="light"
              mb={["10px", null, "20px", null, null]}
              maxWidth="80%"
            >
              {nbsp("4 rady, jak zvládnout pohovor do EY")}
            </Heading>
            <Text
              to={URL.blog}
              color="EYYellow.default"
              fontSize={["1", null, "2", null, null]}
            >
              Chci vědět víc
            </Text>
            {data?.tenderImage1?.childImageSharp && (
              <Image image={data.tenderImage1} cover />
            )}
          </ImageTile>

          <ImageTile to={`${URL.blog}/cesta_s_ey_46_karolina_alexandrova_a_tereza_kosturova_vyuziti_umele_inteligence_nam_pomaha_zaujmout_novou_generaci/`}>
            <Heading
              as="h3"
              color="white.100"
              fontSize={["3", null, "5", null, null]}
              fontWeight="light"
              mb={["10px", null, "20px", null, null]}
              maxWidth="80%"
            >
              {nbsp("Karolína a Tereza nejen o výběrku do EY")}
            </Heading>
            <Text
              as="span"
              to={URL.blog}
              color="EYYellow.default"
              fontSize={["1", null, "2", null, null]}
            >
              Chci vědět víc
            </Text>
            {data?.tenderImage2?.childImageSharp && (
              <Image image={data.tenderImage2} cover />
            )}
          </ImageTile>
        </Flex>
      </section>

      <TopicLinks
        as="section"
        mt="120px"
        items={[
          {
            heading: nbsp("Odvětví, ve kterých můžeš zazářit"),
            link: `${URL.blog}/71-odvetvi-ve-kterych-u-nas-muzes-zazarit/`,
            image: data.topicLinksImage1,
          },
          {
            heading: nbsp("Jak v EY probíhá běžný pracovní den"),
            link: `${URL.blog}/jak-v-ey-probiha-bezny-pracovni-den/`,
            image: data.topicLinksImage2,
          },
          {
            heading: nbsp("Kariérní růst v EY přehledně: kdy postoupíš a jak se ti odměníme"),
            link: `${URL.blog}/karierni_rust_v_ey_prehledne_kdy_postoupis_a_jak_se_ti_odmenime/`,
            image: data.topicLinksImage3,
          },
          {
            heading: nbsp("Kam až vedla cesta našich lidí"),
            link: `${URL.blog}/kam-az-vedla-cesta-nasich-lidi/`,
            image: data.topicLinksImage4,
          },
        ]}
      >
        Co dalšího tě zajímá?
      </TopicLinks>
    </Layout>
  );
};

export const query = graphql`
  query HomePageQuery {
    mdx(frontmatter: { templateKey: { eq: "home-page" } }) {
      frontmatter {
        metaTitle
        metaDescription
        headerImage {
          image {
            childImageSharp {
              gatsbyImageData(width: 1430, quality: 90)
            }
          }
          imageAlt
        }
        title
        subTitle
        ctaText
        ctaLink
        whybewithey {
          image {
            childImageSharp {
              gatsbyImageData(width: 88, height: 88, quality: 90, layout: FIXED)
            }
          }
          name
          position
          department
          description
        }
      }
    }
    tenderImage1: file(
      relativePath: { eq: "4-rady-jak-na-vyberko-do-ey.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 600, quality: 90)
      }
    }
    tenderImage2: file(relativePath: { eq: "podcast-1.jpeg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 600, quality: 90)
      }
    }
    topicLinksImage1: file(
      relativePath: { eq: "hp-topic/odvetvi-ve-kterych-muzes-zazarit.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 1440, quality: 90)
      }
    }
    topicLinksImage2: file(
      relativePath: { eq: "hp-topic/jak-v-ey-probiha-bezny-pracovni-den.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 1440, quality: 90)
      }
    }
    topicLinksImage3: file(
      relativePath: { eq: "hp-topic/karierni-rust-v-ey-prehledne-kdy-postoupis-a-jak-se-ti-odmenime.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 1440, quality: 90)
      }
    }
    topicLinksImage4: file(
      relativePath: { eq: "hp-topic/kam-az-vedla-cesta-nasich-lidi.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 1440, quality: 90)
      }
    }
  }
`;

export default HomePage;

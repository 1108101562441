import React from "react";

import { Container, Wrapper } from "../../style/Grid";
import Bubble from "../../components/Bubble";
import YellowLine from "../../components/YellowLine";
import withBubbleList from "../../components/withBubbleList";
import { Heading } from "../../style/Heading";
const IndexBubbles = withBubbleList(Bubble);

const WhyJoin = ({ content }) => (
  <Wrapper as="section" bg="EYOffBlack.default">
    <Container
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      mb="20px"
    >
      <YellowLine
        left={true}
        rightSpace={40}
        mr="auto"
        position="relative"
        top={[null, null, null, "auto", "93px"]}
        mt={["65px", null, null, null, "0"]}
        mb={["70px", null, null, "90px", "0"]}
      >
        <Heading
          as="h2"
          color="white.100"
          fontSize={["4", null, null, "5", null]}
          fontWeight="light"
          lineHeight="3"
        >
          Proč se přidat
          <br />
          právě k EY?
        </Heading>
      </YellowLine>
      <IndexBubbles why={content} />
    </Container>
  </Wrapper>
);

export default WhyJoin;

import styled from "styled-components";
import {
  space,
  color,
  layout,
  typography,
  flexbox,
  background,
} from "styled-system";
import propTypes from "@styled-system/prop-types";
import { themeGet } from "@styled-system/theme-get";

import { Link as GatsbyLink } from "gatsby";
import React from "react";

const ImageTile = styled(GatsbyLink)`
  position: relative;
  display: flex;
  text-decoration: none;
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;
  transition: background-size ${themeGet("times.transition")};
  overflow: hidden;

  ${space}
  ${flexbox}
  ${color}
  ${layout}
  ${typography}
  ${background}

  &:before {
    content: "";
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: linear-gradient(180deg, rgba(46, 46, 56, 0) 0%, #2e2e38 90%, #2e2e38 100%);
  }

  &:hover .image {
    transform: scale(1.1);
  }

  & > * {
    position: relative;
    z-index: 11;
  }
`;

ImageTile.displayName = `ImageTile`;

ImageTile.defaultProps = {
  width: ["300px", "350px", null, "450px", "600px"],
  height: ["280px", "315px", null, "400px", "540px"],
  flexDirection: "column",
  justifyContent: "flex-end",
  padding: ["25px", null, null, "40px", null],
};

ImageTile.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.flexbox,
  ...propTypes.background,
};

export { ImageTile };

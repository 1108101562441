import React from "react";

import { Box } from "../style/Grid";

const withBubbleList = Element => props => (
  <Box>
    {props.why.map((prop, index) => (
      <Element {...prop} left={index % 2 === 0} />
    ))}
  </Box>
);

export default withBubbleList;
